import React, { Fragment } from "react";
import {
  mdiBellOutline,
  mdiEyeOutline,
  mdiLocationExit,
  mdiLogout,
  mdiMenu,
} from "@mdi/js";
import Icon from "@mdi/react";
import {
  Badge,
  Button,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";

import Input from "../Input/TextField";
import { getUserInfo } from "../../../services/common.service";

export default function Header(props) {
  const { toggleSidebar, setToggleSidebar } = props;
  const userInfo = getUserInfo();

  return (
    <>
      <div className="w-full z-20 bg-white py-2 px-6 fixed inset-x-0 top-0 border-b border-stone-200">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <div
              className="w-9 h-9 mr-5 flex justify-center items-center bg-stone-100 rounded-lg cursor-pointer"
              onClick={() => {
                setToggleSidebar(!toggleSidebar);
              }}
            >
              <Icon path={mdiMenu} size="24px" className="text-gray-600" />
            </div>
            <div className="w-36">
              <Input placeholder="search..." label={false} />
            </div>
          </div>
          <div className="flex items-center">
            <div className="px-5 border-r border-stone-200">
              <Badge content="5">
                <IconButton className="bg-gray200 shadow-none hover:shadow-none h-8 w-8">
                  <Icon
                    path={mdiBellOutline}
                    size="16px"
                    className="text-black"
                  />
                </IconButton>
              </Badge>
            </div>
            <div className="pl-5 flex items-center">
              <Menu placement="bottom-end">
                <MenuHandler>
                  <div className="flex h-9 cursor-pointer">
                    <div className="w-8 h-8">
                      <img
                        src="/assets/image/admin.jpg"
                        alt="profile-img"
                        className="w-full h-full rounded-full"
                      />
                    </div>
                    <span className="mx-2 my-1">
                      {userInfo?.firstName}
                    </span>
                  </div>
                </MenuHandler>
                <MenuList className="min-w-[150px]">
                  <MenuItem className="flex items-center hover:!text-primary">
                    <Icon path={mdiEyeOutline} size={"18px"} className="mr-2" />
                    View Profile
                  </MenuItem>
                  <MenuItem className="hover:!text-red400">
                    <button
                      className="flex items-center"
                      onClick={() => {
                        localStorage.removeItem('token');
                        window.location.reload();
                      }}
                    >
                      <Icon path={mdiLogout} size={"18px"} className="mr-2" />
                      Logout
                    </button>
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

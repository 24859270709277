import { Button } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import AddFormModal from "./AddFormModal";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useReactNavigator } from "../../utils/reactNavigator";

const columnHelper = createColumnHelper();

const formdata = [
  {
    _id: 1,
    thumbnail: "/assets/image/no-thumbnail.png",
    title: "Vadtal dvishtabdi mahotsav",
    eventStartDate: "04-09-24",
    eventEndDate: "11-09-24",
    isEditable: true,
    sabhya: 300,
    flag: "/assets/image/admin.png",
  },
];

const FormList = () => {
  const [open, setOpen] = useState(false);
  const { navigate } = useReactNavigator();
  const [data, setData] = useState(() => []);

  const getFormData = async () => {
    setData(formdata || []);
  };

  useEffect(() => {
    getFormData();
  }, []);

  const openCreateLinkModal = () => {
    setOpen(!open);
  };

  const columns = [
    columnHelper.accessor("thumbnail", {
      cell: (info) => (
        <img
          src={
            info.getValue()?.default
              ? info.getValue()?.default
              : "/assets/image/no-thumbnail.png"
          }
          alt="thumbnail"
          className="w-[100px] rounded-lg"
        />
      ),
      header: () => <span>Thumbnail</span>,
    }),
    columnHelper.accessor("title", {
      cell: (info) => info.getValue(),
      header: () => <span>Title</span>,
    }),
    columnHelper.accessor("eventStartDate", {
      cell: (info) => info.getValue(),
      header: () => <span>Event Start Date</span>,
    }),
    columnHelper.accessor("eventEndDate", {
      cell: (info) => info.getValue(),
      header: () => <span>Event End Date</span>,
    }),
    columnHelper.accessor("sabhya", {
      cell: (info) => info.getValue(),
      header: () => <span>Total Sabhya</span>,
    }),
    columnHelper.accessor("flag", {
      cell: (info) => (
        console.log(info.getValue()?.default, "ddddddddddd"),
        (
          <img
            src={
              info.getValue()?.default
                ? info.getValue()?.default
                : "/assets/image/no-thumbnail.png"
            }
            alt="thumbnail"
            className="w-[100px] rounded-lg"
          />
        )
      ),
      header: () => <span>Flag</span>,
    }),
    columnHelper.display({
      header: () => <span className="text-center">Actions</span>,
      id: "actions",
      cell: (props) => {
        return (
          <span className="flex items-center justify-center">
            <span className="w-9 h-9 border border-black900 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer group text-black900">
              <button
              // onClick={() => openCreateLinkModal(props.row.original?._id)}
              >
                <Icon
                  icon="ic:baseline-edit"
                  className="text-2xl transition-all duration-150 text-black900"
                />
              </button>
            </span>
            <span className="w-9 h-9 border border-black900 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer group hover:text-black900">
              <button>
                <Icon
                  icon="material-symbols:delete"
                  className="transition-all duration-150 text-2xl text-black900"
                />
              </button>
            </span>
            <span className="w-9 h-9 border border-black900 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer group hover:text-black900">
              <button
                onClick={() => {
                  navigate(`${props.row.original?._id}`);
                }}
              >
                <Icon
                  icon="ion:enter"
                  className="text-2xl transition-all duration-150 text-black900"
                />
              </button>
            </span>
          </span>
        );
      },
    }),
  ];

  const table = useReactTable(
    {
      data,
      columns,
      getCoreRowModel: getCoreRowModel(),
      getRowId: (row) => row.id,
    },
    [data]
  );

  return (
    <>
      <div className="h-[calc(100vh_-_81px)] p-10 bg-[#F8F8F8] overflow-auto">
        <div className="flex items-center justify-between pb-[15px] mb-[20px] border-b-[#D7D7D7] border-b border-solid">
          <div className="text-[25px] text-[#212121]">Form List</div>
          <Button
            color="blue"
            className="flex items-center min-w-[150px]"
            onClick={() => openCreateLinkModal()}
          >
            <Icon icon="ic:baseline-plus" className="mr-1 text-2xl" /> create
            New Form
          </Button>
        </div>
        <div className="overflow-auto">
          <table className="w-full overflow-auto">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => {
                return (
                  <tr className="border-b border-stone-200 text-sm bg-[#F2F2F2] text-[#535353]">
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          className="text-left font-semibold p-2"
                        >
                          {header.isPlaceholder ? null : (
                            <div
                              {...{
                                className: header.column.getCanSort()
                                  ? "cursor-pointer select-none"
                                  : "last:text-center",
                              }}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </div>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>
            <tbody className="className='last:!border-b-0'">
              {table.getRowModel().rows.map((row) => {
                return (
                  <tr
                    key={row.id}
                    className="border-b border-stone-200 text-sm"
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td key={cell.id} className="px-2 py-4">
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <AddFormModal open={open} setOpen={setOpen} />
    </>
  );
};

export default FormList;

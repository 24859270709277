import toastrpkg from "toastr";
import { ROLES, ROLES_PERMISSIONS } from "../constants/common.constants";
import { s3Url } from "./api.service";
import axios from "axios";

export const toastr = {
    show: function (message, type, title, timeOut) {
        let options = {
            showMethod: "slideDown",
            hideMethod: "slideUp",
            timeOut: timeOut ? timeOut : 2000,
        };
        if (type === "success") {
            toastrpkg.success(message, title, options);
        } else if (type === "error") {
            toastrpkg.error(message, title, options);
        } else if (type === "info") {
            toastrpkg.info(message, title, options);
        } else if (type === "warning") {
            toastrpkg.warning(message, title, options);
        } else {
            toastrpkg.info(message, title, options);
        }
    },
};

export const getUserInfo = () => {
    try {
        const loggedInUser = localStorage.getItem("loggedInUser");
        return JSON.parse(loggedInUser);
    } catch (err) {
        return null;
    }
}

export const verifyUserPermission = (requiredPermission) => {
    try {
        const userInfo = getUserInfo();
        if (userInfo.roleName === ROLES.ADMIN) return true;

        if ([ROLES.HOS, ROLES.HOD].includes(userInfo.roleName)) {
            if (ROLES_PERMISSIONS[userInfo.roleName].includes(requiredPermission)) {
                return true;
            }
        }

        return userInfo?.permissions?.includes(requiredPermission);
    } catch (err) {
        return false;
    }
}

export const getProfileImage = (userObj, gender) => {
    const photoShow = window?.localStorage?.getItem('female-hide') === 'show' ? true : false;
    switch (gender) {
        case 'male':
            return userObj?.profileImgUrl ? `${s3Url}${userObj?.profileImgUrl}` : "/assets/image/profilepicture.png";
        case 'female':
            if(!photoShow) return '/assets/image/female-avatar.jpg';
            return userObj?.profileImgUrl ? `${s3Url}${userObj?.profileImgUrl}` : "/assets/image/female-avatar.jpg";
        default:
            return userObj?.profileImgUrl ? `${s3Url}${userObj?.profileImgUrl}` : "/assets/image/profilepicture.png";
    }            
}

export const uploadDataOnGoDaddyServer = async (formData, url) => {
    try {
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200 && response?.data?.imageNames) {
        return response.data.imageNames;
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  }
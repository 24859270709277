import { Button, Input } from "@material-tailwind/react";
import Icon from "@mdi/react";
import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Switch, Transition, TransitionChild } from "@headlessui/react";
import { mdiClose, mdiTrashCan } from "@mdi/js";
import lodash from "lodash";
import { s3Url } from "../../services/api.service";

const BooksUpload = ({
  open,
  setOpen,
  albumId,
  selectedAudio,
  refreshData,
}) => {
  const [bookDetail, setBookDetail] = useState({});
  const [enabled, setEnabled] = useState(false);
  const [imageFile, setImageFile] = useState("");
  const [imagePreviewFile, setImagePreviewFile] = useState("");
  const [pdfFile, setPDFFile] = useState("");

  const setCurrentDetail = (key, value) => {
    const newBookDetail = lodash.cloneDeep(bookDetail);
    newBookDetail[key] = value;
    setBookDetail(newBookDetail);
  };

  const handlePDFFileChnage = (pdffile) => {
    const file = pdffile?.[0];
    if (file) {
      const fileExtension = file?.name.substring(
        file?.name.lastIndexOf(".") + 1,
        file?.name.length
      );
      const validataionMessage = "Please Choose Correct File Format";
      if (file?.name?.length == 0) {
        setPDFFile("");
        return;
      }
      if (fileExtension === "pdf") {
        setPDFFile(pdffile);
      } else {
        setPDFFile("");
      }
    }
  };

  const handleImageFileChange = (vfile) => {
    const file = vfile?.[0];
    if (file) {
      const fileExtension = file?.name.substring(
        file?.name.lastIndexOf(".") + 1,
        file.name.length
      );
      const validataionMessage = "Please choose correct image format";
      if (file?.name?.length == 0) {
        setImageFile("");
        // toastr.show(validataionMessage);
        return;
      }
      if (
        fileExtension === "jpg" ||
        fileExtension === "webp" ||
        fileExtension === "png" ||
        fileExtension === "jpeg" ||
        fileExtension === "gif" ||
        fileExtension === "bmp"
      ) {
        setImageFile(vfile);
        // preview image
        const reader = new FileReader();
        reader.onload = (e) => {
          setImagePreviewFile(e?.target?.result);
        };
        reader.readAsDataURL(file);
      } else {
        setImageFile("");
        // toastr.show(validataionMessage);
        return;
      }
    }
  };

  const resetForm = () => {
    setOpen(false);
    setCurrentDetail({});
    setBookDetail({});
    setEnabled(false);
    setImageFile("");
    setPDFFile("");
    setImagePreviewFile("");
  };

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => {}}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </TransitionChild>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between pb-4 border-b border-gray400">
                    <div className="text-lg font-semibold text-orange-600">
                      Add Book
                    </div>
                    <Icon
                      path={mdiClose}
                      size={1}
                      className="cursor-pointer text-[#D4D4D4] hover:text-[#A5A5A5] transition-all duration-150"
                      onClick={() => {
                        setOpen(false);
                      }}
                    />
                  </div>
                  <div className="p-6">
                    <div className="flex flex-col">
                      <div className="flex flex-col">
                        <div className="flex-grow flex justify-center">
                          <div className="max-w-md w-full">
                            <div className="bg-white w-full px-10 py-[30px] rounded-[10px]">
                              <div className="mb-5">
                                <Input
                                  label="Title"
                                  className=" w-full"
                                  value={bookDetail?.["title"] || ""}
                                  onChange={(e) =>
                                    setCurrentDetail("title", e.target.value)
                                  }
                                />
                              </div>
                              <div className="mb-5">
                                <Input
                                  label="Author"
                                  className=" w-full"
                                  value={bookDetail?.["author"] || ""}
                                  onChange={(e) =>
                                    setCurrentDetail("author", e.target.value)
                                  }
                                />
                              </div>
                              {/* {newImageUpload && ( */}
                              <>
                                <div className="mb-5">
                                  <Input
                                    label="Image Upload"
                                    accept=".jpg, .png, .jpeg, .gif, .webp, .bmp, .tif, .tiff|image/*"
                                    className="w-full"
                                    id="file"
                                    type="file"
                                    onChange={(e) =>
                                      handleImageFileChange(e?.target?.files)
                                    }
                                  />
                                </div>
                                <div className="mb-5">
                                  <img
                                    src={
                                      imagePreviewFile
                                        ? imagePreviewFile
                                        : "/assets/image/no-thumbnail.png"
                                    }
                                    className="w-[100px]"
                                  />
                                </div>
                              </>
                              {/* )} */}
                              {/* {newImageUpload && ( */}
                              <>
                                <div className="mb-5">
                                  <Input
                                    label="File Upload"
                                    accept=".pdf"
                                    className="w-full"
                                    id="file"
                                    type="file"
                                    onChange={(e) =>
                                      handlePDFFileChnage(e?.target?.files)
                                    }
                                  />
                                </div>
                              </>
                              {/* )} */}
                              <div className="flex items-center">
                                <div className="mr-3 text-blue-gray-500">
                                  Visibility
                                </div>
                                <Switch
                                  checked={enabled}
                                  onChange={setEnabled}
                                  className={`${
                                    enabled ? "bg-blue-600" : "bg-gray-200"
                                  } relative inline-flex h-6 w-11 items-center rounded-full`}
                                >
                                  <span className="sr-only">
                                    Enable notifications
                                  </span>
                                  <span
                                    className={`${
                                      enabled
                                        ? "translate-x-6"
                                        : "translate-x-1"
                                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                  />
                                </Switch>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-right flex justify-end mt-6">
                      <div className="mr-5">
                        <Button
                          color="red"
                          variant="outlined"
                          className="capitalize text-base"
                          onClick={() => resetForm()}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div>
                        <Button
                          className="capitalize text-base bg-orange-600"
                          //   onClick={() =>
                          //     selectedAudio?._id ? updateAudio() : createAudio()
                          //   }
                          //   disabled={isLoading}
                        >
                          {"Create"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default BooksUpload;

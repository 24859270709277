import { Button, Input } from "@material-tailwind/react";
import Icon from "@mdi/react";
import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Switch, Transition, TransitionChild } from "@headlessui/react";
import { mdiClose, mdiTrashCan } from "@mdi/js";
import AreaField from "../Common/Input/AreaField";
import DatePickerField from "../Common/Rsuite-Datepicker";
import { SABHA_TYPES } from "../../constants/common.constants";
import { Radio, Typography, Checkbox } from "@material-tailwind/react";
import { useSelector } from "react-redux";
import { DateRangePicker } from "rsuite";

const genderOptions = ["All", "Male", "Female"];
const AddFormModal = ({ open, setOpen }) => {
  const [eventStartDate, setEventStartDate] = useState("");
  const [eventEndDate, setEventEndDate] = useState("");
  const [dayCount, setDayCount] = useState(0);
  const [formVisibility, setFormVisibility] = useState({});
  const [formData, setFormData] = useState({ gender: "All" });
  console.log("🚀 ~ AddFormModal ~ formData:", formData);
  const [infoCollectionType, setInfoCollectionType] = useState("dateSelection")
  

  const handleRadioChange = (value) => {
    setFormVisibility({ isActive: value });
    if (value === "Sabha") {
      setFormData({ 
        ...formData, 
        formVisibility: { sabhas: [] } 
      });
    } else if (value === "Department") {
      setFormData({ 
        ...formData, 
        formVisibility: { department: [] } 
      });
    }
  };

  useEffect(() => {
    setValue("dayCount", dayCount);
  }, [dayCount]);

  const { HODList: Departments } = useSelector(
    (state) => state.SaintLeaderReducer
  );

  const setValue = (name, value) => {
    if (value) {
      setFormData({ ...formData, [name]: value });
    }
  };

  const DepartmentsList = Departments.map((dep) => ({
    label: dep.departmentName,
    value: dep._id,
    sabhaId: dep.sabhaId,
  }));

  const SabhaList = SABHA_TYPES.map((item) => ({
    label: item.name,
    value: item._id,
  }));

  const handleFileChange = (name,file) => {
    setValue([name], file);
  };

  const updateDayCount = (start, end) => {
    if (start && end) {
      const diffTime = Math.abs(end - start);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1; // Adding 1 to include both start and end dates
      setDayCount(diffDays);
    } else {
      setDayCount(0);
    }
  };

  const handleRadChange = (value) => {
    setFormVisibility({ isActive: value });
    // Reset sabhas or departments based on the selected radio button
    if (value === "Sabha") {
      setFormData({ 
        ...formData, 
        formVisibility: { sabhas: [] } 
      });
    } else if (value === "Department") {
      setFormData({ 
        ...formData, 
        formVisibility: { department: [] } 
      });
    }
  };
  
  const handleSabhaChange = (sabha) => {
    const updatedSabhas = [...(formData.formVisibility?.sabhas || [])];
    
    if (updatedSabhas.includes(sabha)) {
      setFormData({ 
        ...formData, 
        formVisibility: {
          ...formData.formVisibility,
          sabhas: updatedSabhas.filter((s) => s !== sabha)
        }
      });
    } else {
      setFormData({ 
        ...formData, 
        formVisibility: {
          ...formData.formVisibility,
          sabhas: [...updatedSabhas, sabha]
        }
      });
    }
  };

  const handleDepartmentChange = (dept) => { 
    const updatedDepartment = [...(formData.formVisibility?.department || [])];
    
    if (updatedDepartment.includes(dept)) {
      setFormData({ 
        ...formData, 
        formVisibility: {
          ...formData.formVisibility,
          department: updatedDepartment.filter((s) => s !== dept)
        }
      });
    } else {
      setFormData({ 
        ...formData, 
        formVisibility: {
          ...formData.formVisibility,
          department: [...updatedDepartment, dept]
        }
      });
    }
  };

   const handleSingleCheckboxChange = (name, isChecked) => {
    setFormData({ 
      ...formData, 
      [name]: isChecked 
    });
  };

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => {}}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </TransitionChild>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-6xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between pb-4 border-b border-gray400">
                    <div className="text-lg font-semibold text-orange-600">
                      New Form
                    </div>
                    <Icon
                      path={mdiClose}
                      size={1}
                      className="cursor-pointer text-[#D4D4D4] hover:text-[#A5A5A5] transition-all duration-150"
                      onClick={() => {
                        setOpen(false);
                      }}
                    />
                  </div>
                  <div className="p-6">
                    <div className="flex flex-col">
                      <div className="flex flex-col">
                        <div className="flex-grow flex justify-center">
                          <div className="w-full">
                            <div className="bg-white w-full px-10 py-[30px] rounded-[10px]">
                              {/* {newImageUpload && ( */}
                              <>
                                <div className="mb-5">
                                  <Input
                                    label="Image Upload"
                                    accept=".jpg, .png, .jpeg, .gif, .webp, .bmp, .tif, .tiff|image/*"
                                    className="w-full"
                                    id="file"
                                    type="file"
                                    onChange={(e) =>
                                      handleFileChange(
                                        "formImg",
                                        e?.target?.files[0]
                                      )
                                    }
                                  />
                                </div>
                                <div className="mb-5">
                                  <Input
                                    label="Upload Flag Image"
                                    accept=".jpg, .png, .jpeg, .gif, .webp, .bmp, .tif, .tiff|image/*"
                                    className="w-full"
                                    id="file"
                                    type="file"
                                    onChange={(e) =>
                                      handleFileChange(
                                        "flagImg",
                                        e?.target?.files[0]
                                      )
                                    }
                                  />
                                </div>
                                <div className="mb-5">
                                  <img
                                    // src={
                                    //   imagePreviewFile
                                    //     ? imagePreviewFile
                                    //     : "/assets/image/no-thumbnail.png"
                                    // }
                                    src={"/assets/image/no-thumbnail.png"}
                                    className="w-[100px]"
                                  />
                                </div>
                              </>
                              {/* )} */}
                              <div className="mb-5">
                                <Input
                                  label="Form Title"
                                  className=" w-full"
                                  value={formData?.title || ""}
                                  onChange={(e) =>
                                    setValue("title", e.target.value)
                                  }
                                />
                              </div>
                              <div className="mb-5">
                                <AreaField
                                  placeholder="Form Description"
                                  rows="4"
                                  className=" w-full"
                                  value={formData?.description || ""}
                                  onChange={(e) =>
                                    setValue("description", e.target.value)
                                  }
                                />
                              </div>
                              <div className="mb-5">
                                <div className="text-sm mb-5 text-gray300 font-semibold inline-flex items-center">
                                  <span>Info. Collection type</span>
                                </div>
                                <div className="flex">
                                  <div className="flex items-center mr-5">
                                    <Radio
                                      defaultChecked
                                      name="dateSelection"
                                      value="dateSelection"
                                      checked={
                                        infoCollectionType === "dateSelection"
                                      }
                                      label={
                                        <span className="text-sm text-gray300 font-semibold">
                                          Date selection
                                        </span>
                                      }
                                      onChange={() => {
                                        setInfoCollectionType("dateSelection");
                                      }}
                                    />
                                  </div>
                                  <div className="flex items-center">
                                    <Radio
                                      name="dateSelection"
                                      value="approval"
                                      checked={
                                        infoCollectionType === "approval"
                                      }
                                      label={
                                        <span className="text-sm text-gray300 font-semibold">
                                          Approval
                                        </span>
                                      }
                                      onChange={() => {
                                        setInfoCollectionType("approval");
                                      }}
                                    />
                                  </div>
                                </div>
                                {infoCollectionType === "dateSelection" && (
                                  <div className="max-w-[300px] mt-3">
                                    <DateRangePicker
                                      format="MM/dd/yyyy"
                                      showMeridian
                                      cleanable={false}
                                      placement="autoVerticalStart"
                                      onChange={(date) => {
                                        setValue("eventDuration", date);
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="mb-5 flex items-center">
                                <div className="text-sm text-gray300 font-semibold block mr-3">
                                  Total Present Days
                                </div>
                                <div className=" appearance-none text-sm text-black900 outline-none rounded-lg ">
                                  {dayCount ? dayCount : 0}
                                </div>
                              </div>
                              <div className="flex items-center">
                                <label className="text-sm text-gray300 font-semibold mb-2 inline-block">
                                  Form Visibility
                                  <div className="flex items-center">
                                    <div className="w-1/2">
                                      <Radio
                                        name="All"
                                        value="All"
                                        label="All"
                                        onChange={() =>
                                          // handleRadioChange("All")
                                          setValue("")
                                        }
                                        checked={
                                          formVisibility.isActive === "All"
                                        }
                                      />
                                    </div>
                                    <div className="w-1/2">
                                      <Radio
                                        name="Sabha"
                                        value="Sabha"
                                        label="Sabha"
                                        onChange={() =>
                                          handleRadioChange("Sabha")
                                        }
                                        checked={
                                          formVisibility.isActive === "Sabha"
                                        }
                                      />
                                    </div>
                                    <div className="w-1/2">
                                      <Radio
                                        name="Department"
                                        value="Department"
                                        label="Department"
                                        onChange={() =>
                                          handleRadioChange("Department")
                                        }
                                        checked={
                                          formVisibility.isActive ===
                                          "Department"
                                        }
                                      />
                                    </div>
                                  </div>
                                </label>
                              </div>
                              {formVisibility.isActive === "Sabha" ? (
                                <div className="mb-5 ml-5 flex items-center">
                                  {SABHA_TYPES?.map((sabha) => {
                                    return (
                                      <div className="">
                                        <Checkbox
                                          className="w-4 h-4"
                                          label={
                                            <Typography className="poppins text-sm">
                                              {sabha.name}
                                            </Typography>
                                          }
                                          checked={(
                                            formData.formVisibility?.sabhas ||
                                            []
                                          ).includes(sabha.name)}
                                          onChange={() =>
                                            handleSabhaChange(sabha.name)
                                          }
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                <></>
                              )}

                              {formVisibility.isActive === "Department" ? (
                                <div className="mb-5 ml-5 flex items-center flex-wrap">
                                  {DepartmentsList?.map((dep) => {
                                    return (
                                      <div className="flex items-center">
                                        <Checkbox
                                          className="h-4 w-4"
                                          label={
                                            <Typography className="poppins">
                                              {dep.label}
                                            </Typography>
                                          }
                                          checked={(
                                            formData.formVisibility
                                              ?.department || []
                                          ).includes(dep.label)}
                                          onChange={() =>
                                            handleDepartmentChange(dep.label)
                                          }
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                <></>
                              )}

                              <div className="mb-5">
                                <label className="text-sm text-gray300 font-semibold inline-block">
                                  Gender
                                </label>
                                <div>
                                  {genderOptions.map((item) => {
                                    return (
                                      <Radio
                                        key={item}
                                        name="gender"
                                        defaultChecked={
                                          formData.gender === item
                                        }
                                        label={
                                          <Typography className="poppins">
                                            {item}
                                          </Typography>
                                        }
                                        value={item} // Set the value to the current item
                                        onChange={(e) =>
                                          setValue("gender", e.target.value)
                                        } // Update formData when a different option is selected
                                      />
                                    );
                                  })}
                                </div>
                              </div>
                              <div className="mb-5">
                                <label className="text-sm text-gray300 font-semibold mb-2 inline-block">
                                  Filled up Date Limit
                                </label>
                                <DateRangePicker
                                  format="MM/dd/yyyy hh:mm aa"
                                  showMeridian
                                  placement="autoVerticalStart"
                                  onChange={(date) => {
                                    setValue("filledUpDateLimit", date);
                                  }}
                                />
                              </div>
                              <div className="mb-5">
                                <label className="text-sm text-gray300 font-semibold mb-2 inline-block">
                                  Event End Date
                                </label>
                                <div className="flex gap-2">
                                  <DatePickerField
                                    placeholder="Select Start Date"
                                    cleanable={false}
                                    onChange={(date) => {
                                      setValue("eventEndDate", date);
                                    }}
                                  />
                                </div>
                              </div>
                              <div>
                                <Checkbox
                                  label={
                                    <Typography className="poppins">
                                      Editing field after field up by sabhya
                                    </Typography>
                                  }
                                  checked={formData["isEditable"]}
                                  onChange={(e) =>
                                    handleSingleCheckboxChange(
                                      "isEditable",
                                      e.target.checked
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-right flex justify-end mt-6">
                      <div className="mr-5">
                        <Button
                          color="red"
                          variant="outlined"
                          className="capitalize text-base"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div>
                        <Button className="capitalize text-base bg-orange-600">
                          {"Create"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default AddFormModal;
